import { useMutation } from "@tanstack/react-query";

import { Showroom } from "@models/Showroom";
import { SpecialRequestOldModel } from "@models/SpecialRequest";
import { Organization } from "@models/old/Organization";
import axiosInstance from "@services/api/config";

export const postConfirmSpecialRequestEndpoint = {
  path: (
    organizationId: Organization["id"],
    specialRequestId: SpecialRequestOldModel["id"],
  ) =>
    `/organizations/${organizationId}/special-requests/${specialRequestId}/accept`,
};

export interface SpecialRequestConfirmInput {
  specialRequestId: SpecialRequestOldModel["id"];
  body: {
    sellerId: string;
    selectedLanguage?: string;
  };
}

interface Output {
  showroom: Pick<Showroom, "id">;
}

export function usePostConfirmSpecialRequest(organizationId: string) {
  return useMutation({
    mutationFn: (params: SpecialRequestConfirmInput) =>
      axiosInstance
        .post<Output>(
          postConfirmSpecialRequestEndpoint.path(
            organizationId,
            params.specialRequestId,
          ),
          params.body,
        )
        .then((r) => r.data),
  });
}

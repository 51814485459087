import React from "react";

import { Trans, useTranslation } from "react-i18next";
import { difference } from "remeda";

import { DashboardPanel } from "@components/data-display/DashboardPanel";
import { AppointmentStatus } from "@dashboard/appointment-status";
import BookingDetails from "@dashboard/booking-details";
import InvitationStatus from "@dashboard/invitation-status";
import TodaysAppointments, {
  useTodaysAppointmentsState,
} from "@dashboard/todays-appointments";
import { UserRoleList } from "@models/types/enums";
import { useAuthenticatedUser } from "@services/authentication/useAuthentication";
import { pageWithAccessControl } from "@shared/components/access-control";

function DashboardViewComponent() {
  const { t } = useTranslation();
  const currentUser = useAuthenticatedUser();

  const todaysAppointmentsState = useTodaysAppointmentsState();

  return (
    <div className="h-full flex flex-col relative">
      <div className="bg-primaryLightElectricBlue h-[168px] w-full" />
      <div className="h-full w-full flex flex-col absolute pb-12">
        <div className="bg-primaryLightElectricBlue pt-12 px-6">
          <div className="pb-6">
            <h1 className="heading-1 pb-2">
              <Trans
                i18nKey="Dashboard.greeting"
                values={{ firstName: currentUser.firstName }}
              >
                Dashboard.greeting
              </Trans>
            </h1>
            {t("Dashboard.welcome")}
          </div>
        </div>
        <div>
          <div className="grid grid-cols-10 gap-7 ml-7 mr-7">
            {/* Appointment Status */}
            <AppointmentStatus />

            {/* Appointment Details */}
            <DashboardPanel className="col-span-10" condensed>
              <TodaysAppointments {...todaysAppointmentsState} />
            </DashboardPanel>

            {/* Booking Details */}
            <DashboardPanel className="col-span-10">
              <BookingDetails />
            </DashboardPanel>

            {/* Invitation Status */}
            <DashboardPanel className="col-span-7">
              <InvitationStatus />
            </DashboardPanel>
          </div>
        </div>
      </div>
    </div>
  );
}
const DashboardView = React.memo(DashboardViewComponent);

export default pageWithAccessControl(
  difference(UserRoleList, ["FREELANCE"]), // everyone but freelancers
  DashboardView,
);

import React, { PropsWithChildren } from "react";

interface DashboardPanelProps extends PropsWithChildren<{}> {
  className?: string;
  condensed?: boolean;
}

export function DashboardPanel({
  children,
  className,
  condensed = false,
}: DashboardPanelProps) {
  return (
    <div
      style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
      className={`bg-white ${condensed ? "px-6 pb-6 pt-2" : "p-6"} mb-4 rounded-lg ${className}`}
    >
      {children}
    </div>
  );
}

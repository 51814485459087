import React from "react";

import { Trans, useTranslation } from "react-i18next";
import { HiOutlineChevronRight, HiPlusCircle } from "react-icons/hi";
import { groupBy } from "remeda";

import {
  isActiveShowroom,
  isDraftShowroom,
  isPastShowroom,
} from "@app/modules/showroom/helpers";
import Collapse from "@components/data-display/Collapse";
import Button from "@components/data-entry/Button";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import LoadingFetch from "@components/feedback/LoadingFetch";
import { Showroom } from "@models/Showroom";
import ShowroomViewCard from "@sales-campaign/components/miniview-card";
import ShowroomCard from "@sales-campaign/components/showroom/card";
import { DeleteShowroomEndpoint } from "@services/api/showroom/delete-showroom";
import { GetShowroomsEndpoint } from "@services/api/showroom/get-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { ACL_ADMINS } from "@shared/components/access-control/helpers";
import OrderDeadlineFetchAndDisplay from "@shared/order-deadline/OrderDeadlineFetchAndDisplay.container";

import ShowroomCreate from "../../../modules/showroom/ShowroomCreate";
import ShowroomEdit from "../../../modules/showroom/ShowroomEdit";

export default function ShowroomsPage() {
  const { t } = useTranslation();
  const { organization } = useOrganizationAppContext();
  const [showroomIdToEdit, setShowroomIdToEdit] = React.useState<
    string | undefined
  >();
  const createShowroomDrawer = useDrawer({
    backdrop: true,
    needsConfirmation: true,
    onClose: () => setShowroomIdToEdit(undefined),
  });
  const startShowroomEdition = (showroomId: string) => {
    setShowroomIdToEdit(showroomId);
    createShowroomDrawer.openDrawer();
  };
  const [draftCollapsed, setDraftCollapsed] = React.useState(true);
  const [pastCollapsed, setPastCollapsed] = React.useState(false);
  const [showroomToDelete, setShowroomToDelete] = React.useState<
    Showroom | undefined
  >(undefined);
  const { data: showrooms = [], status: showroomsFetchStatus } =
    GetShowroomsEndpoint.useHook({
      organizationId: organization.id,
    });
  const { mutateAsync: deleteShowroom } = DeleteShowroomEndpoint.useHook({
    organizationId: organization.id,
  });

  const activeShowrooms = showrooms.filter(isActiveShowroom);
  const draftShowrooms = showrooms.filter(isDraftShowroom);
  const pastShowrooms = showrooms.filter(isPastShowroom);
  const activeShowroomsPerSeason = groupBy(
    activeShowrooms,
    (item) => `${item.season} ${item.year}`,
  );

  return (
    <div className="w-full h-full flex flex-col gap-6 lg:gap-10 px-6 py-12">
      <h1 className="heading-1">{t("SalesCampaign.dashboard.title")}</h1>
      <div className="flex flex-col lg:flex-row gap-6 grow">
        {/* ACTIVE SALES CAMPAIGNS */}
        <div className="flex flex-col grow flex-start w-full lg:w-1/2 gap-6 lg:gap-10 p-4 lg:p-10 rounded-lg bg-primaryLightElectricBlue">
          {/* ACTIVE CAMPAIGNS HEADER */}
          <div className="flex flex-wrap-reverse gap-4 items-center justify-between">
            <h2 className="heading-2">
              {t("SalesCampaign.dashboard.active-title", {
                count: activeShowrooms.length,
              })}
            </h2>
            {/* CREATE BUTTON IF ACTIVE SALES CAMPAIGNS */}
            {activeShowrooms.length > 0 && (
              <Button
                aclRoles={ACL_ADMINS}
                theme="PRIMARY"
                onClick={createShowroomDrawer.openDrawer}
              >
                <HiPlusCircle />
                {t("SalesCampaign.dashboard.create-campaign-button")}
              </Button>
            )}
          </div>
          <LoadingFetch
            error="could not fetch showrooms"
            status={showroomsFetchStatus}
          >
            {/* CREATE BUTTON WITH NO ACTIVE SALES CAMPAIGNS */}
            {activeShowrooms.length === 0 && (
              <Button
                aclRoles={ACL_ADMINS}
                theme="PRIMARY"
                rounded="large"
                className="flex items-center justify-between w-full h-20 px-6 font-medium rounded-lg py-7"
                onClick={createShowroomDrawer.openDrawer}
              >
                <span className="flex items-center">
                  <HiPlusCircle className="mr-2" />
                  {t("SalesCampaign.dashboard.create-first-campaign-button")}
                </span>
                <HiOutlineChevronRight />
              </Button>
            )}
            {/* ACTIVE SHOWROOMS LIST */}
            {activeShowrooms.length > 0 && (
              <div className="flex flex-col gap-4 lg:gap-6 grow overflow-y-auto">
                {Object.entries(activeShowroomsPerSeason).map(
                  ([season, seasonShowrooms]) => {
                    const [seasonCode, seasonYear] = season.split(" ");
                    return (
                      <React.Fragment key={season}>
                        <div className="flex flex-col">
                          <h3 className="font-bold my-2">{season} </h3>
                          {organization.brand && organization.brand.id && (
                            <OrderDeadlineFetchAndDisplay
                              brandId={organization.brand.id}
                              season={seasonCode}
                              year={parseInt(seasonYear, 10)}
                            />
                          )}
                        </div>
                        {seasonShowrooms.map((showroom) => (
                          <ShowroomCard
                            key={showroom.id}
                            onEdit={() => startShowroomEdition(showroom.id)}
                            onDelete={() => setShowroomToDelete(showroom)}
                            showroom={showroom}
                            organizationId={organization.id}
                            allowsFullEdit={false}
                          />
                        ))}
                      </React.Fragment>
                    );
                  },
                )}
              </div>
            )}
          </LoadingFetch>
        </div>
        <div className="flex flex-col grow w-full lg:w-1/2">
          {/* DRAFT SHOWROOMS */}
          <Collapse
            title={
              <h3 className="heading-3">
                {t("SalesCampaign.dashboard.draft-title", {
                  count: draftShowrooms.length,
                })}
              </h3>
            }
            collapsed={draftCollapsed}
            onToggle={() => {
              setDraftCollapsed(!draftCollapsed);
              if (pastCollapsed) setPastCollapsed(false);
            }}
            className="bg-primaryLightGrey"
          >
            {draftShowrooms.length === 0 &&
              t("SalesCampaign.dashboard.past-content-empty")}
            {draftShowrooms.length > 0 && (
              <div className="flex flex-col h-64 gap-6 overflow-y-auto">
                {draftShowrooms.map((showroom) => (
                  <ShowroomViewCard
                    key={`sales-campaign-miniview-${showroom.id}`}
                    showroom={showroom}
                    status="DRAFT"
                    onEdit={() => startShowroomEdition(showroom.id)}
                    onDelete={() => setShowroomToDelete(showroom)}
                  />
                ))}
              </div>
            )}
          </Collapse>
          {/* PAST SHOWROOMS */}
          <Collapse
            title={
              <h3 className="heading-3">
                {t("SalesCampaign.dashboard.past-title", {
                  count: pastShowrooms.length,
                })}
              </h3>
            }
            collapsed={pastCollapsed}
            onToggle={() => {
              setPastCollapsed(!pastCollapsed);
              if (draftCollapsed) setDraftCollapsed(false);
            }}
            className="bg-primaryLightGrey"
          >
            {pastShowrooms.length === 0 &&
              t("SalesCampaign.dashboard.past-content-empty")}
            {pastShowrooms.length > 0 && (
              <div className="flex flex-col h-64 gap-6 overflow-y-auto">
                {pastShowrooms.map((showroom) => (
                  <ShowroomViewCard
                    key={`sales-campaign-miniview-${showroom.id}`}
                    showroom={showroom}
                    status="PAST"
                    onEdit={() => startShowroomEdition(showroom.id)}
                    onDelete={() => setShowroomToDelete(showroom)}
                  />
                ))}
              </div>
            )}
          </Collapse>
        </div>
        {/* CONFIRMATION MODAL */}
        <ConfirmModal
          show={showroomToDelete !== undefined}
          title={t("SalesCampaign.dashboard.delete")}
          confirmLabel={t("SalesCampaign.dashboard.delete-confirmed")}
          onCancel={() => {
            setShowroomToDelete(undefined);
          }}
          onConfirm={() => {
            // @todo delete showroom
            if (showroomToDelete) {
              deleteShowroom({ showroomId: showroomToDelete.id });
            }
            setShowroomToDelete(undefined);
          }}
        >
          <Trans
            i18nKey="SalesCampaign.dashboard.delete-confirmation"
            values={{ name: showroomToDelete?.name || "" }}
          >
            SalesCampaign.dashboard
            <strong className="font-medium">sales-campaign</strong>.
            <br />
            delete-confirmation
            <br />
          </Trans>
        </ConfirmModal>

        <Drawer
          size="EXTRALARGE"
          name="creating or updating sales campaign drawer"
          {...createShowroomDrawer.props}
        >
          {showroomIdToEdit ? (
            <ShowroomEdit
              onSubmit={createShowroomDrawer.closeWithoutConfirmation}
              showroomId={showroomIdToEdit}
            />
          ) : (
            <ShowroomCreate
              onSubmit={createShowroomDrawer.closeWithoutConfirmation}
            />
          )}
        </Drawer>

        <ConfirmModal
          title={t("SalesCampaign.dashboard.close-showroom-drawer-title")}
          onConfirm={createShowroomDrawer.closeWithoutConfirmation}
          onCancel={createShowroomDrawer.cancelClosingDrawer}
          show={createShowroomDrawer.isConfirming}
        >
          {t("SalesCampaign.dashboard.close-showroom-drawer-confirmation")}
        </ConfirmModal>
      </div>
    </div>
  );
}
